.header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4rem;
    z-index: 1;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.header-logo,
.header-logo a,
.header-logo h5,
.header-clock {
    display: inline-block;
    position: relative;
}
.header-logo {
    height: 2.5rem;
    pointer-events: auto;
}
.header-logo a {
    height: 2.5rem;
}
.header-logo a svg {
    height: 2.5rem;
}
.header-clock {
    float: right;
    padding: .125rem 0;
}
.header-clock div {
    text-align: left!important;
    color: var(--white-900);
}
.header-clock div span {
    padding: 0!important;
}



@media only screen and (max-width: 319px) {
    .header {
        padding: 2.5rem;
    }
    .header-logo,
    .header-logo a,
    .header-logo a svg {
        height: 1.5rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .header {
        padding: 2.5rem;
    }
    .header-logo,
    .header-logo a,
    .header-logo a svg {
        height: 1.5rem;
    }
    .header-clock {
        float: right;
        padding: .125rem 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .header-clock {
        float: right;
        padding: .5rem 0;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) { 
    .header-clock {
        float: right;
        padding: .3125rem 0;
    }
}