.footer {
    position: fixed;
    max-width: unset;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 4rem;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.footer span.caption-regular {
    display: inline-flex;
    position: relative;
    float: right;
    color: var(--black-300);
}



@media only screen and (max-width: 319px) {
    .footer {
        padding: 2.5rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .footer {
        padding: 2.5rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {

}