.hero {
    margin-top: 13.75rem;
    margin-bottom: 4rem;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.hero h1 {
    margin-bottom: 6rem;
}
.hero h1 span {
    display: inline-block;
    position: relative;
}



@media only screen and (max-width: 319px) {
    .hero {
        display: none!important;
    }
    .hero h1 {
        margin-bottom: 3rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .hero {
        margin-top: 7.5rem;
        margin-bottom: 2.5rem;
    }
    .hero h1 {
        margin-bottom: 3rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .hero h1 {
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .hero h1 {
        margin-bottom: 5rem;
    }
}