.error {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    height: 100vh;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.error-inner {
    text-align: left;
}
.error-inner h2 {
    margin-bottom: 2.5rem;
}
.error-inner h4 a {
    color: var(--white-100);
    text-decoration: none;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.error-inner h4 a:hover {
    color: var(--white-900);
}
.error.not-found {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.error.resolution {
    display: none;
}



@media only screen and (max-width: 319px) {
    .error.resolution {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
    }
    .error.not-found {
        display: none!important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    
}